import React from "react";

import AuthProvider from './AuthProvider';
import StorageProvider from './StorageProvider';

import Routes from './Routes';

import './assets/sass/style.scss';
import './assets/vendors/flaticon/flaticon.css';
import './assets/vendors/flaticon2/flaticon.css';

export default () => (
  <AuthProvider>
    <StorageProvider>
      <Routes />
    </StorageProvider>
  </AuthProvider>
);