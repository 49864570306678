import React, { Children } from "react";
import styled from 'styled-components';

import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../Portlet';

const Wrapper = styled.div`
    div.item {
        border-top: 1px solid #ebedf2;
        margin-top: 1rem;
        padding-top: 1rem;

        div.note:not(:first-child) {
            border-top: 1px solid #ebedf2;
            margin-top: 1rem;
            padding-top: 1rem;
        }
    }

    .kt-widget__top {
        .kt-widget__action {
            flex: 1;
            text-align: right;
        }
    }
    

    .kt-widget__bottom {
        margin-top: 1rem !important;

        .kt-widget__item:not(:first-child) {
            margin-left: 2rem;
        }
        
        .kt-widget__item {
            flex-grow: 0 !important;
        }
    }
`;

export const ListItem = ({children}) => (
    <Wrapper>{children}</Wrapper>
);