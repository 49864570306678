import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";

import FirebaseAuth from "../services/auth";

import './../assets/sass/pages/login/login-3.scss';

export const Logout = () => {
    useEffect(() => {
        console.log('logout');
        FirebaseAuth.logout();
    }, []);

    return (
        <h1>Please wait...</h1>
    )
};

export const Callback = ({ history, match }) => {
    const login = () => {
        FirebaseAuth.login.link.complete();
    };

    useEffect(() => {
        login();
    }, [match.path]);

    return (
        <h1>Please wait...</h1>
    )
};

export default () => {
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);

    return (
        <React.Fragment>
            <div className="kt-login__logo">
                <a href="/">
                    <img src="https://www.slovenia-explorer.com/themes/default/assets/images/svg/logo-slovenian-explorer.svg" />
                </a>
            </div>
            <div className="kt-login__signin">
                <div className="kt-login__head">
                    <h3 className="kt-login__title">Sign In To BOO</h3>
                </div>
                {(completed) ? (
                    <div role="alert" className="alert alert-success">
                        <a href={completed.link}>Click here</a>
                    </div>
                ) : (
                    <Formik
                        initialValues={{
                            email: ""
                        }}
                        validate={values => {
                            const errors = {};

                            if (!values.email) {
                                errors.email = "AUTH.VALIDATION.REQUIRED_FIELD";
                            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                errors.email = "AUTH.VALIDATION.INVALID_FIELD";
                            } else if (values.email.split('@')[1] !== 'slovenia-explorer.com') {
                                errors.email = "AUTH.VALIDATION.INVALID_FIELD";
                            }

                            return errors;
                        }}
                        onSubmit={async (values, { setStatus, setSubmitting }) => {
                            console.log(values);
                            setLoading(true);
                            setSubmitting();
                            await FirebaseAuth.login.link.send(values.email).then((res) => {
                                console.log(res);
                                setCompleted(res);
                            }).catch(() => {
                                setStatus("AUTH.VALIDATION.INVALID_LOGIN");
                            }).then(() => {
                                setLoading(false);
                                setSubmitting(false);
                            });
                        }}
                    >
                        {({
                            values,
                            status,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <Form className="kt-form">
                                { console.log(status, errors) }
                                {status && (
                                    <div role="alert" className="alert alert-danger">
                                        <div className="alert-text">{status}</div>
                                    </div>
                                )}
                                <div className="input-group">
                                    <Field type="email" name="email" placeholder="Email" className="form-control" />
                                </div>
                                <div className="kt-login__actions">
                                    <button type="submit" className="btn btn-brand btn-elevate kt-login__btn-primary" disabled={(isSubmitting) ? 'disabled' : ''}>Sign In</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </React.Fragment>
    )
};