import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import moment from 'moment';

import { SingleDatePicker } from 'react-dates';

import { LayoutContext } from '../../layout';

import { Portlet, PortletBody } from '../../layout/elements/Portlet';

import API from '../../services/api';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const actions = {
    createTour: async (date, ids) => {
        return API.tours.create({ date: date.from.format('YYYYMMDD'), ids }).then(res => res._id);
    }
};

const LayoutSubHeader = (history, { disabledSubmit, setDisabledSubmit }, date, selectedRows, results) => {
    const bck = (ts, obj) => {
        return moment(ts, 'X').subtract(obj).format('YYYYMMDD');
        /*setRange({
            from: range.from.subtract(obj),
            to: range.to.subtract(obj)
        });*/
    };

    const fwd = (ts, obj) => {
        return moment(ts, 'X').add(obj).format('YYYYMMDD');
        /*setRange({
            from: range.from.add(obj),
            to: range.to.add(obj)
        });*/
    };

    const createTour = async () => {
        setDisabledSubmit(true);
        let id = await actions.createTour(selectedRows[0].startDate, selectedRows.map(row => row.id));
        setDisabledSubmit(false);
        history.push(`/tours/${id}`);
    };

    const exportTours = async () => {
        setDisabledSubmit(true);
        API.export.tour({
            from: date.from.format('YYYYMMDD'),
            to: date.to.format('YYYYMMDD')
        }).then(response => {
            const filename = `${date.from.format('YYYYMMDD')}.csv`;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            // works in IE11
            if (typeof window.navigator.msSaveBlob === 'function') {
                window.navigator.msSaveBlob(
                    response.data, filename
                );
            } else {
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
            setDisabledSubmit(false);
        });
    };

    return (
        <React.Fragment>
            {(results !== 0) && (    
                <button className="btn btn-info mr-3" onClick={exportTours} disabled={(disabledSubmit) && "disabled"}>
                    Export
                </button>
            )}
            {(selectedRows.length !== 0) && (
                <button className="btn btn-success mr-3" onClick={createTour} disabled={(disabledSubmit) && "disabled"}>
                    Create new tour
                </button>
            )}
            <Link to={`?from=${bck(date.from.unix(), { weeks: 1 })}&to=${bck(date.from.unix(), { weeks: 1 })}`} className="btn kt-subheader__btn-daterange">
                <i className="flaticon2-fast-back pr-0"></i>
            </Link>
            <Link to={`?from=${bck(date.from.unix(), { days: 1 })}&to=${bck(date.from.unix(), { days: 1 })}`} className="btn kt-subheader__btn-daterange">
                <i className="flaticon2-back pr-0"></i>
            </Link>
            <a href="#" className="btn kt-subheader__btn-daterange">
                <span className="kt-subheader__btn-daterange-title">Date:</span>&nbsp;
                <span className="kt-subheader__btn-daterange-date">{date.from.format('D.M.')}</span>
                <i className="flaticon2-calendar-1"></i>
            </a>
            <Link to={`?from=${fwd(date.from.unix(), { days: 1 })}&to=${fwd(date.from.unix(), { days: 1 })}`} className="btn kt-subheader__btn-daterange">
                <i className="flaticon2-next pr-0"></i>
            </Link>
            <Link to={`?from=${fwd(date.from.unix(), { weeks: 1 })}&to=${fwd(date.from.unix(), { weeks: 1 })}`} className="btn kt-subheader__btn-daterange">
                <i className="flaticon2-fast-next pr-0"></i>
            </Link>
        </React.Fragment>
    )
};

export default ({ history }) => {
    const [isLoading, setLoading] = useState(true);
    const [results, setResults] = useState([]);
    const [selection, setSelection] = useState([]);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const query = useQuery();
    const [date, setDate] = useState({
        from: (query.get('from') && moment(query.get('from'), 'YYYYMMDD')) || moment().add(1, 'd').startOf('day'),
        to: (query.get('to') && moment(query.get('to'), 'YYYYMMDD')) || moment().add(1, 'd').startOf('day')
    });

    const setSubHeader = useContext(LayoutContext).elements.subheader[1];
    const setBreadcrumbs = useContext(LayoutContext).elements.breadcrumbs[1];

    const columns = [
        /*{
            name: 'ID',
            selector: 'id',
        },
        {
            name: 'PID',
            selector: 'parentBookingId',
        },*/
        {
            name: 'Date',
            button: true,
            cell: row => <Link to={`/tours/${row._id}`}>{row._id}</Link>
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true
        }
    ];

    const rowSelector = (e) => {
        setSelection(e.selectedRows);
        //(e.selectedRows.length !== 0) ? setSubHeader(LayoutSubHeader({ range, setRange }, true)) : setSubHeader(LayoutSubHeader({ range, setRange }, false))
    };

    useEffect(() => {
        setSubHeader(LayoutSubHeader(history, { disabledSubmit, setDisabledSubmit }, date, selection, (isLoading) ? 0 : results.length));
        setBreadcrumbs({
            title: "Tours",
            links: [
                ["Tours", "/tours"]
            ]
        });
    }, [date.from, date.to, selection, results.length, isLoading, disabledSubmit]);

    useEffect(() => {
        setLoading(true);
        API.tours.list({
            from: date.from.format('YYYYMMDD'),
            to: date.to.format('YYYYMMDD')
        }).then(res => {
            setResults(res.results);
            setLoading(false);
        });
    }, [date.from, date.to]);

    useEffect(() => {
        setDate({
            from: query.get('from') ? moment(query.get('from'), 'YYYYMMDD') : moment().add(1, 'd').startOf('day'),
            to: query.get('to') ? moment(query.get('to'), 'YYYYMMDD') : moment().add(1, 'd').startOf('day')
        });
    }, [query.get('from'), query.get('to')]);

    return (
        <Portlet fluidHeight>
            <PortletBody fit>
                <DataTable
                    columns={columns}
                    selectableRowsNoSelectAll
                    selectableRows
                    data={results}
                    progressPending={isLoading}
                    onSelectedRowsChange={rowSelector}
                    pagination
                    noHeader
                />
            </PortletBody>
        </Portlet>
    )
};