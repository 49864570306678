import React, { useEffect, useState, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { Switch } from "react-router-dom";
import moment from 'moment';
import clsx from "clsx";
import _ from "lodash";

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';

import { LayoutContext } from '../../layout';
import { SetSubHeader } from '../../layout/segments/SubHeader';

import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../layout/elements/Portlet';

import Info from './elements/Info';
import Guides from './elements/Guides';
import Transports from './elements/Transports';
import Activities from './elements/Activities';
import Vouchers from './elements/Vouchers';
import Notes from './elements/Notes';
import Expenses from './elements/Expenses';
import Documents from './elements/Documents';

import API from '../../services/api';

export default ({ history, match }) => {
    const [isLoading, setLoading] = useState(true);
    const [tour, setTour] = useState(null);

    const setBreadcrumbs = useContext(LayoutContext).elements.breadcrumbs[1];
    const setSubHeader = useContext(LayoutContext).elements.subheader[1];

    const id = match.params.id;

    const load = useCallback(
        () => {
            setLoading(true);
            API.tours.get({ id }).then(res => {
                setTour(res);
                setLoading(false);
            });
        },
        [id]
    );

    /*const load = ({ id }) => {
        setLoading(true);
        API.tours.get({ id }).then(res => {
            setTour(res);
            setLoading(false);
        });
    };*/

    useEffect(() => {
        setBreadcrumbs({
            title: `Tour`,
            links: [
                ["Tours", "/tours"],
                [id, `/tours/${id}`]
            ]
        });
    }, [id, setBreadcrumbs]);

    useEffect(() => {
        load({id});
    }, [id, load]);

    useEffect(() => {
        if (!tour) {
            setSubHeader(
                <span className="btn btn-text btn-sm btn-upper btn-bold disabled">Loading <span className="spinner spinner-track spinner-primary spinner-sm ml-3 mr-3"></span></span>
            )
        } else {
            setSubHeader(
                <React.Fragment>
                    {(isLoading) && (<span className="btn btn-text btn-sm btn-upper btn-bold disabled">Loading <span className="spinner spinner-track spinner-primary spinner-sm ml-3 mr-3"></span></span>)}
                    <span className="btn btn-primary btn-sm btn-upper btn-bold">{moment(tour.date, 'YYYYMMDD').format('D.M.YYYY')}</span>
                    <span className="btn btn-secondary btn-sm btn-upper btn-bold">{tour.status}</span>
                </React.Fragment>
            )
        }
    }, [tour, isLoading]);

    if (!tour) return null;

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Info load={load} tour={tour} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Activities load={load} tour={tour} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Vouchers load={load} tour={tour} />
                </Col>
                <Col>
                    <Documents load={load} tour={tour} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Expenses travel load={load} tour={tour} />
                </Col>
                <Col>
                    <Expenses other load={load} tour={tour} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Notes load={load} tour={tour} />
                </Col>
            </Row>
        </React.Fragment>
    )
};