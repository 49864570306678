import React, { useEffect, useState, useContext } from "react";
import {
    Formik,
    useFormikContext,
    FormikHelpers,
    FormikProps,
    Form,
    Field,
    FieldProps,
} from 'formik';
import _ from "lodash";

import { Row, Col } from 'reactstrap';

import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../layout/elements/Portlet';
import { ListItem as ActivityWidget } from '../../../layout/elements/widgets/Activities';

import API from '../../../services/api';

import { StorageContext } from '../../../StorageProvider';
import { AutoSave } from './AutoSave';

export default ({tour}) => {
    const [status, setStatus] = useState(0);

    const processSubmit = (values, actions) => {
        setStatus(1);
        API.tours.update({ id: tour._id, payload: { notes: values.notes } }).then(async () => {
            actions.setSubmitting(false);
            setStatus(2);
        });
    };

    return (
        <Portlet>
            <PortletHeader title="Notes" toolbar={
                <PortletHeaderToolbar>
                    {(status === 1) && (<div className="spinner spinner-track spinner-primary spinner-sm mr-3"></div>)}
                    {(status === 2) && (<span>Changes saved!</span>)}
                </PortletHeaderToolbar>
            } />
            <PortletBody>
                <Formik
                    initialValues={{
                        notes: _.get(tour, "data.notes", "")
                    }}
                    onSubmit={processSubmit}
                >
                    {props => (
                        <form className="kt-form" onSubmit={props.handleSubmit}>
                            <AutoSave setStatus={setStatus} />
                            <Field as="textarea" className="form-control" rows="9" name="notes" placeholder="Notes" />
                        </form>
                    )}
                </Formik>
            </PortletBody>
        </Portlet>
    );
};