import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { AuthContext } from './../../AuthProvider';

export default () => {
    const { user } = useContext(AuthContext);
    return (
        <div className="kt-header  kt-header--fixed">
            <div className="kt-container  kt-container--fluid ">
                <div className="kt-header-menu-wrapper kt-grid__item kt-grid__item--fluid">
                    <div className="kt-header-menu kt-header-menu-mobile ">
                        <ul className="kt-menu__nav ">
                            <li className="kt-menu__item kt-menu__item--submenu kt-menu__item--rel kt-menu__item--open kt-menu__item--here">
                                <Link to="/" className="kt-menu__link kt-menu__toggle">
                                    <span className="kt-menu__link-text">Dashboard</span><i className="kt-menu__ver-arrow la la-angle-right"></i>
                                </Link>
                            </li>
                            <li className="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel">
                                <Link to="/bookings" className="kt-menu__link kt-menu__toggle">
                                    <span className="kt-menu__link-text">Bookings</span><i className="kt-menu__ver-arrow la la-angle-right"></i>
                                </Link>
                            </li>
                            <li className="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel">
                                <Link to="/tours" className="kt-menu__link kt-menu__toggle">
                                    <span className="kt-menu__link-text">Tours</span><i className="kt-menu__ver-arrow la la-angle-right"></i>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="kt-header__brand   kt-grid__item">
                    <a className="kt-header__brand-logo" href="/">
                        <img alt="Logo" src="https://www.slovenia-explorer.com/themes/default/assets/images/svg/logo-slovenian-explorer.svg" style={{ height: "4em" }} />
                    </a>
                </div>

                <div className="kt-header__topbar kt-grid__item">
                    <div className="kt-header__topbar-item kt-header__topbar-item--user">
                        <div className="kt-header__topbar-wrapper">
                            <span className="kt-header__topbar-welcome kt-visible-desktop">Hi,</span>
                            <span className="kt-header__topbar-username kt-visible-desktop">{user.profile.email}</span>
                            <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">{user.profile.email.charAt(0)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};