import React, { useEffect, useState, useContext } from "react";
import {
    Formik,
    useFormikContext,
    Field,
} from 'formik';
import _ from "lodash";

import MaskedInput from 'react-text-mask';
import { Row, Col } from 'reactstrap';

import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../layout/elements/Portlet';
import { Widget2 as Widget, Widget3 as List } from '../../../layout/elements/Widgets';

import API from '../../../services/api';

import { StorageContext } from '../../../StorageProvider';

const FormMasks = {
    hours: [[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/], "00:00"]
};

const Populate = ({ products, value }) => {
    const { values, setValues } = useFormikContext();

    useEffect(() => {
        let selected = _.find(products, { code: values.product.code });
        let newValues = {...values};
        _.set(newValues, 'product.relation', selected.transfer.relation.summer);
        _.set(newValues, 'product.distance', selected.transfer.distance);
        setValues(newValues);
    }, [value]);

    return null;
}

export default ({ load, tour }) => {
    const { products, guides, transports } = useContext(StorageContext).settings;
    const [editing, setEditing] = useState(false);

    let data = {
        product: _.get(tour.data, 'product', null),
        guide: _.get(tour.data, 'guides[0]', null),
        transport: _.get(tour.data, 'transports[0]', null),
        pax: _.get(tour, 'pax', {})
    };

    const processSubmit = (values, actions) => {
        let payload = {
            product: {
                code: values.product.code,
                transfer: {
                    relation: values.product.relation || null,
                    distance: values.product.distance || null
                },
                pickup: {
                    time: values.product.pickupTime || null,
                    location: values.product.pickupLocation || null
                },
                reservationPP: values.product.reservationpp || null
            },
            guides: [{
                code: values.guide.code,
                allowance: values.guide.allowance || null,
                cash: values.guide.cash || null
            }],
            transports: [{
                code: values.transport.code
            }]
        };
        API.tours.update({ id: tour._id, payload: payload }).then(async () => {
            await load();
            setEditing(false)
        });
    };

    const editor = (
        <Formik
            initialValues={{
                product: {
                    code: data.product && data.product.code || Object.keys(products)[0],
                    relation: _.get(data.product, "transfer.relation", ""),
                    distance: _.get(data.product, "transfer.distance", ""),
                    pickupTime: _.get(data.product, "pickup.time", ""),
                    pickupLocation: _.get(data.product, "pickup.location", ""),
                    reservationpp: _.get(data.product, "reservationpp", "")
                },
                guide: {
                    code: (data.guide && data.guide.code) || null,
                    cash: _.get(data.guide, "cash", ""),
                    allowance: _.get(data.guide, "allowance", "")
                },
                transport: {
                    code: (data.transport && data.transport.code) || null,
                    /*startKm: _.get(data.transport, 'km.start', 0),
                    endKm: _.get(data.transport, 'km.end', 0),
                    exGas: _.get(data.transport, 'expenses.gas', 0),
                    exCarwash: _.get(data.transport, 'expenses.carwash', 0)*/
                }
            }}
            onSubmit={processSubmit}
        >
            {props => (
                <form className="kt-form" onSubmit={props.handleSubmit}>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <Row>
                                    <Col sm="4">
                                        <label>Pickup time</label>
                                        {/*<Field type="text" className="form-control" name="product.pickupTime" placeholder="08:00" />*/}
                                        <Field name="product.pickupTime" render={({ field }) => {
                                            return <MaskedInput type="text" className="form-control" mask={FormMasks.hours[0]}
                                                {...field}
                                                placeholder={FormMasks.hours[1]} />
                                        }} />
                                    </Col>
                                    <Col sm="8">
                                        <label>Pickup location</label>
                                        <Field type="text" className="form-control" name="product.pickupLocation" placeholder="Union Ljubljana" />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Guide</label>
                                <Field className="form-control" as="select" name="guide.code">
                                    <option value="">----</option>
                                    {Object.keys(guides.list).map((guide, n) => <option value={guide} key={n}>{`${guide} / ${guides.list[guide].name[0]} ${guides.list[guide].name[1]}`}</option>)}
                                </Field>
                            </div>
                            <div className="form-group">
                                <label>Salary</label>
                                <Field className="form-control" as="select" name="guide.allowance">
                                    <option value="">----</option>
                                    {Object.keys(guides.allowances).map((allowance, n) => <option value={guides.allowances[n]} key={n}>{guides.allowances[n]}</option>)}
                                </Field>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Vehicle</label>
                                <Field className="form-control" as="select" name="transport.code">
                                    <option value="">----</option>
                                    {Object.keys(transports).map((transport, n) => <option value={transport} key={n}>{`${transport} / ${transports[transport].plates} / ${transports[transport].desc} / ${transports[transport].seats}`}</option>)}
                                </Field>
                            </div>
                            <div className="form-group">
                                <label>Product</label>
                                <Field className="form-control" as="select" name="product.code">
                                    {Object.keys(products).map((product, n) => <option value={product} key={n}>{`${products[product].code} / ${products[product].name}`}</option>)}
                                </Field>
                            </div>
                            <div className="form-group">
                                <label>Relation</label>
                                <Field as="textarea" rows="2" className="form-control" name="product.relation" placeholder="Ljubljana - Bled - Ljubljana" />
                                <div className="input-group mt-1">
                                    <Field type="number" className="form-control" name="product.distance" placeholder="300" />
                                    <div className="input-group-append"><span className="input-group-text">km</span></div>
                                </div>
                                <Populate products={products} value={props.values.product.code} />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Cash</label>
                                <div className="input-group">
                                    <Field type="number" className="form-control" name="guide.cash" placeholder="0" />
                                    <div className="input-group-append"><span className="input-group-text">&euro;</span></div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Reservation PP</label>
                                <Field type="text" className="form-control" name="product.reservationpp" placeholder="0" />
                            </div>
                        </Col>
                    </Row>
                    <button type="submit" className="btn btn-label-success btn-block btn-upper">Save</button>
                </form>
            )}
        </Formik>
    );

    const viewer = () => {
        let selected = {
            product: products[data.product.code],
            guides: guides.list[data.guide.code],
            allowance: guides.allowances[data.guide.allowance],
            transports: transports[data.transport.code]
        };

        return (
            <Row>
                <Col>
                    <List>
                        <div className="item">
                            <span className="icon"><i className="flaticon2-pin"></i></span>
                            <p>{data.product.pickup.time}, {data.product.pickup.location}</p>
                        </div>
                        {Object.keys(data.pax).map(activity => (
                            <div key={activity} className="item">
                                <span className="icon"><i className="flaticon2-avatar"></i></span>
                                <p><strong className="mr-3">{activity}</strong><strong className="mr-3">{_.reduce(data.pax[activity], (sum, n) => (sum + Number(n)), 0)}</strong>{data.pax[activity].join(' / ')}</p>
                            </div>
                        ))}
                    </List>
                </Col>
                <Col>
                    <List>
                        {(selected.guides) ? (
                        <div className="head">
                            <span className="kt-badge kt-badge--unified-primary kt-badge--bold">{selected.guides.code}</span>
                            <div className="info">
                                <h5>{selected.guides.name[0]} {selected.guides.name[1]}</h5>
                                <p>{selected.guides.phone}</p>
                                <p>{selected.guides.email[0]}</p>
                            </div>
                        </div>
                        ) : null}
                        <div className="item">
                            <span className="icon"><i className="flaticon2-map"></i></span>
                            <h6>Allowance: {data.guide.allowance || "-"}</h6>
                        </div>
                    </List>
                </Col>
                <Col>
                    <List>
                        {(selected.transports) ? (
                        <div className="head">
                            <span className="kt-badge kt-badge--unified-primary kt-badge--bold">{selected.transports.code}</span>
                            <div className="info">
                                <h5>{selected.transports.plates}</h5>
                                <p>{selected.transports.desc} <i className="flaticon2-avatar"></i> {selected.transports.seats}</p>
                            </div>
                        </div>
                        ) : null}
                        <div className="item">
                            <span className="icon"><i className="flaticon2-map"></i></span>
                            <h6>{data.product.transfer.relation}</h6>
                        </div>
                    </List>
                </Col>
                <Col>
                    <List>
                        <div className="item">
                            <span className="icon"><i className="flaticon2-map"></i></span>
                            <h6>Cash: {data.guide.cash || "-"}€</h6>
                        </div>
                        <div className="item">
                            <span className="icon"><i className="flaticon2-map"></i></span>
                            <h6>Reservation PP: {data.product.reservationPP || "-"}</h6>
                        </div>
                    </List>
                </Col>
            </Row>
        )

        return (
            <Widget background="https://www.slovenia-explorer.com/storage/app/media/thumb/340x180.crop/2ba2bd97-bbc5-4a41-989d-fc0dd0ecb8f9.jpg">
                <div className="shadow">
                    <h3 className="kt-font-light">{selected.name}</h3>
                    <div className="head">
                        <button className="btn btn-light btn-bold btn-sm">{data.code}</button>
                        <button className="btn btn-primary btn-sm btn-upper btn-bold" onClick={() => setEditing(!editing)}>{editing ? "Close" : "Edit"}</button>
                    </div>
                </div>
            </Widget>
        )
    };

    if (!data.product.code && !editing) return (
        <Portlet>
            <PortletHeader title="Tour" toolbar={
                <PortletHeaderToolbar>
                    <button className="btn btn-primary btn-sm btn-upper btn-bold" onClick={() => setEditing(true)}>Edit</button>
                </PortletHeaderToolbar>
            } />
            <PortletBody>
                <h6>No product selected</h6>
            </PortletBody>
        </Portlet>
    )

    return (
        <Portlet>
            <PortletHeader title="Tour" toolbar={
                <PortletHeaderToolbar>
                    <button className="btn btn-primary btn-sm btn-upper btn-bold" onClick={() => setEditing(!editing)}>{(editing) ? "Close" : "Edit"}</button>
                </PortletHeaderToolbar>
            } />
            <PortletBody>
                {(editing) ? editor : viewer()}
            </PortletBody>
        </Portlet>
    )
}