import React from "react";
import { Route, Switch } from "react-router-dom";

import List from './tours/List';
import Single from './tours/Single';

export default () => (
    <Switch>
        <Route path="/tours/:id" component={Single} />
        <Route path="/tours" exact component={List} />
    </Switch>
);