import { useEffect } from 'react';
import _ from 'lodash';
import { useFormikContext } from 'formik';

var debounced = _.debounce(formik => formik.submitForm(), 2000, { 'maxWait': 10000 });

export function AutoSave({setStatus}) {
    const formik = useFormikContext();

    useEffect(() => {
        if (formik.isValid && formik.dirty && !formik.isSubmitting) {
            setStatus(0);
            debounced(formik);
        }
    }, [formik.values]);

    return null;
}