import React, { useState, useEffect } from "react";

import API from "./services/api";

export const StorageContext = React.createContext();

export default ({ children }) => {
    let [ready, setReady] = useState(false);
    let [settings, setSettings] = useState(false);

    const fetchSettings = async () => {
        API.settings.get()
            .then(data => setSettings(data))
            .then(() => setReady(true));
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    if (ready) return (
        <StorageContext.Provider value={{ settings, ready }}>
            {children}
        </StorageContext.Provider>
    )
    return null;
};