import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import moment from 'moment';

import { LayoutContext } from '../layout';

import { Portlet, PortletBody } from '../layout/elements/Portlet';

import API from '../services/api';

const actions = {
    createTour: async (date, ids) => {
        return API.tours.create({ date: moment(date, 'x').format('YYYYMMDD'), ids }).then(res => res._id);
    }
};

const LayoutSubHeader = ({ submit, range, selection }) => {
    const history = useContext(LayoutContext).__history[0];
    const setSubHeader = useContext(LayoutContext).elements.subheader[1];

    const [rangeVal, setRange] = range;
    const [submittingVal, setSubmitting] = submit;
    //const selection = selection;

    const bck = (obj) => {
        setRange({
            from: rangeVal.from.subtract(obj),
            to: rangeVal.to.subtract(obj)
        });
    };

    const fwd = (obj) => {
        setRange({
            from: rangeVal.from.add(obj),
            to: rangeVal.to.add(obj)
        });
    };

    const createTour = async () => {
        setSubmitting(true);
        let id = await actions.createTour(selection[0].startDate, selection.map(row => row.id));
        setSubmitting(false);
        history.push(`/tours/${id}`);
    };

    useEffect(() => {
        setSubHeader(
            <React.Fragment>
                {(selection.length !== 0) && (
                    <button className="btn btn-success mr-3" onClick={createTour} disabled={(submittingVal) && "disabled"}>
                        Create new tour
                    </button>
                )}
                <button className="btn kt-subheader__btn-daterange" onClick={() => bck({ weeks: 1 })}>
                    <i className="flaticon2-fast-back pr-0"></i>
                </button>
                <button className="btn kt-subheader__btn-daterange" onClick={() => bck({ days: 1 })}>
                    <i className="flaticon2-back pr-0"></i>
                </button>
                <a href="#" className="btn kt-subheader__btn-daterange">
                    <span className="kt-subheader__btn-daterange-title">Date:</span>&nbsp;
                <span className="kt-subheader__btn-daterange-date">{rangeVal.from.format('D.M.')}</span>
                    <i className="flaticon2-calendar-1"></i>
                </a>
                <button className="btn kt-subheader__btn-daterange" onClick={() => fwd({ days: 1 })}>
                    <i className="flaticon2-next pr-0"></i>
                </button>
                <button className="btn kt-subheader__btn-daterange" onClick={() => fwd({ weeks: 1 })}>
                    <i className="flaticon2-fast-next pr-0"></i>
                </button>
            </React.Fragment>
        )
    }, [rangeVal, selection]);

    return null;
};

export const Results = ({range, selector}) => {
    const [isLoading, setLoading] = useState(true);
    const [results, setResults] = useState([]);

    const columns = [
        /*{
            name: 'ID',
            selector: 'id',
        },
        {
            name: 'PID',
            selector: 'parentBookingId',
        },*/
        {
            name: 'Experience',
            selector: 'productExternalId',
            sortable: true,
        },
        {
            name: 'PAX',
            selector: 'fields.totalParticipants',
            sortable: true
        },
        {
            name: 'Pickup',
            selector: 'fields.startTimeStr',
            sortable: true
        },
        {
            name: 'Customer',
            cell: (row) => (`${row.customer.firstName} ${row.customer.lastName}`)
        },
        {
            name: 'Contact',
            selector: 'customer.phoneNumber'
        },
        {
            name: 'Channel',
            selector: 'channel.title',
            sortable: true
        },
        {
            name: 'Reference Code',
            selector: 'confirmationCode',
            sortable: true
        },
        {
            name: 'Total',
            selector: 'totalPriceAmount',
            sortable: true,
            cell: (row) => (
                `${row.totalPriceAmount} ${row.currency}`
            )
        },
        {
            name: 'Payment status',
            selector: 'paidType',
            sortable: true
        }
    ];

    useEffect(() => {
        setLoading(true);
        API.bookings.list({
            from: range.from,
            to: range.to
        }).then(res => {
            setResults(res.results);
            setLoading(false);
        });
    }, [range.to, range.from]);

    return (
        <DataTable
            columns={columns}
            selectableRowsNoSelectAll
            selectableRows
            data={results}
            progressPending={isLoading}
            onSelectedRowsChange={selector}
            pagination
            noHeader
        />
    )
};

export default ({history}) => {
    const [range, setRange] = useState({
        from: moment().add(1, 'd').startOf('day'),
        to: moment().add(1, 'd').endOf('day')
    });
    const [selection, setSelection] = useState([]);
    const [disabledSubmit, setDisabledSubmit] = useState(false);

    const setBreadcrumbs = useContext(LayoutContext).elements.breadcrumbs[1];

    useEffect(() => {
        setBreadcrumbs({
            title: "Bookings",
            links: [
                ["Bookings", "/bookings"]
            ]
        });
    }, []);

    return (
        <Portlet fluidHeight>
            <LayoutSubHeader
                submit={[disabledSubmit, setDisabledSubmit]}
                range={[range, setRange]}
                selection={ selection }
            />
            <PortletBody fit>
                <Results range={{to: range.to.unix(), from: range.from.unix()}} selector={e => setSelection(e.selectedRows)} />
            </PortletBody>
        </Portlet>
    )
};