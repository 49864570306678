import React, { useEffect, useState, useContext } from "react";
import {
    Formik,
    useFormikContext,
    FormikHelpers,
    FormikProps,
    Form,
    Field,
    FieldProps,
} from 'formik';
import _ from "lodash";

import { Row, Col } from 'reactstrap';

import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../layout/elements/Portlet';
import { Widget2 as Widget, Widget3 as List } from '../../../layout/elements/Widgets';

import API from '../../../services/api';

import { StorageContext } from '../../../StorageProvider';

const Document = ({tour, item}) => {
    const [status, setStatus] = useState(0);

    const LoadDocument = (type) => {
        setStatus(1);
        API.document.get(tour._id, type).then(response => {
            const filename = `${tour._id}_${type}.pdf`;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            // works in IE11
            if (typeof window.navigator.msSaveBlob === 'function') {
                window.navigator.msSaveBlob(
                    response.data, filename
                );
            } else {
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
            setStatus(2);
        });
    };

    return (
        <div className="d-flex align-items-center">
            <label className="checkbox checkbox-lg checkbox-light-white flex-shrink-0 m-0 mr-4">
                {(status === 0) && (<input disabled type="checkbox" value="1" />)}
                {(status === 1) && (<div className="spinner spinner-track spinner-primary spinner-sm mr-3"></div>)}
                {(status === 2) && (<input disabled type="checkbox" value="1" checked />)}
            </label>
            <div className="d-flex flex-column font-weight-bold">
                <button className="btn btn-link-dark font-weight-bold mb-1 p-0" onClick={() => LoadDocument(item.type)}>{item.name}</button>
            </div>
        </div>
    )
};

export default ({ load, tour }) => {
    const { products, guides, transports } = useContext(StorageContext).settings;
    
    let data = _.get(tour, 'documents', []);

    return (
        <Portlet>
            <PortletHeader title="Documents" />
            <PortletBody>
                { data.map(item => <Document key={item.type} tour={tour} item={item} />)}
            </PortletBody>
        </Portlet>
    )
};