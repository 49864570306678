import React, { useState, useEffect } from "react";

import Auth from "./services/auth";

export const AuthContext = React.createContext();

export default ({ children }) => {
    let [ready, setReady] = useState(false);
    let [authState, setAuthState] = useState(false);
    let [user, setUser] = useState(null);

    useEffect(() => {
        Auth.state((user) => {
            if (user) {
                setUser({
                    uid: user.uid,
                    profile: {
                        displayName: user.displayName,
                        email: user.email,
                        emailVerified: user.emailVerified,
                        photoURL: user.photoURL,
                        isAnonymous: user.isAnonymous
                    }
                });
                setAuthState(true);
                setReady(true);
            } else {
                setReady(true);
                setAuthState(false);
                setUser(null);
            }
        });
    }, []);

    if (ready) return (
        <AuthContext.Provider value={{ user: user, auth: authState, ready: ready }}>
            {children}
        </AuthContext.Provider>
    )
    return null;
};