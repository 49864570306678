import React from "react";
import styled from 'styled-components';

const WidgetStyle1 = styled.div`
    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;

        span.kt-badge {
            height: 4rem;
            width: 4rem;
            font-size: 1.2rem;
        }

        div.info {
            padding: 0 1.2rem;
            flex-grow: 1;

            h5 {
                font-size: 1.1rem;
                font-weight: 500;
                color: #595d6e;
                transition: color .3s ease;
                margin: 0;
            }

            p {
                font-size: 1rem;
                font-weight: 400;
                color: #74788d;
                margin: 0;
            }
        }
    }

    .items {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px dashed #ebedf2;

        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
        }

        h5 {
            font-size: 1rem;
            font-weight: 400;
            padding-right: 1.25rem;
            margin: 0;
        }

        p {
            font-weight: 500;
            font-size: 1.1rem;
            margin: 0;
        }
    }
`;

export const Widget1 = ({ avatar, children }) => (
    <WidgetStyle1 avatar={avatar}>{children}</WidgetStyle1>
);

const WidgetStyle2 = styled.div`
    background-image: url('${props => props.background}');
    min-height: 12rem;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;

    h3 {
        position: absolute;
        bottom: 0;
        display: block;
        z-index: 1;
        padding: 2rem;
        margin: 0;
    }

    .shadow {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom,rgba(0,0,0,0) 10%,rgba(0,0,0,.1) 30%,rgba(0,0,0,.5) 70%) no-repeat scroll 0 0;
    }

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 1rem 2rem;

        button.btn-light {
            border: 1px;
            background-color: rgba(255,255,255,.2);
            color: #fff;
        }

        button.btn-label-brand {
            background-color: rgba(89, 29, 241, 0.4);
            color: #fff;

            &:hover {
                background-color: #591df1;
            }
        }
    }
`;

export const Widget2 = ({ background, children }) => (
    <WidgetStyle2 background={background}>{children}</WidgetStyle2>
);

const WidgetStyle3 = styled.div`
    & > div {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px dashed #ebedf2;
    }

    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
        }

        .icon {
            padding-right: 1.25rem;
            font-size: 1.4rem;
        }

        h6 {
            flex-grow: 1;
            margin: 0;
        }

        p {
            flex-grow: 1;
            margin: 0;
        }
    }

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;

        span.kt-badge {
            height: 4rem;
            width: 4rem;
            font-size: 1.2rem;
        }

        div.info {
            padding: 0 1.2rem;
            flex-grow: 1;

            h5 {
                font-size: 1.1rem;
                font-weight: 500;
                color: #595d6e;
                transition: color .3s ease;
                margin: 0;
            }

            p {
                font-size: 1rem;
                font-weight: 400;
                color: #74788d;
                margin: 0;
            }
        }
    }
`;

export const Widget3 = ({ children }) => (
    <WidgetStyle3>{children}</WidgetStyle3>
);