import React, { useEffect, useState, useContext } from "react";
import {
    Formik,
    useFormikContext,
    FormikHelpers,
    FormikProps,
    Form,
    Field,
    FieldProps,
} from 'formik';
import _ from "lodash";
import moment from 'moment';

import { Row, Col } from 'reactstrap';

import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../layout/elements/Portlet';
import { ListItem as ActivityWidget } from '../../../layout/elements/widgets/Activities';

import API from '../../../services/api';

import { Results as Bookings } from '../../Bookings';

import { StorageContext } from '../../../StorageProvider';

const Editor = ({activity, load, setShowEditor}) => {
    const { products } = useContext(StorageContext).settings;

    const data = activity.data;

    const processSubmit = (values, actions) => {
        let payload = {
            pax: [
                values.pax[0] || 0,
                values.pax[1] || 0,
                values.pax[2] || 0
            ],
            discount: values.discount,
            notes: {
                operations: values.notes.operations || "",
                financial: values.notes.financial || "",
                staff: values.notes.staff || ""
            },
            activity: values.activity,
            customer: {
                name: values.customer.name,
                phone: values.customer.phone
            },
            invoice: {
                total: values.invoice.total,
                status: values.invoice.status
            },
            channel: {
                ref: values.channel.ref
            }
        };
        API.tours.activities.update({ tour: activity.tour, activity: activity._id, payload: payload }).then(async () => {
            await load();
            setShowEditor(false);
        });
    };

    return (
        <Formik
            initialValues={{
                pax: [
                    _.get(data, "pax[0]", ""),
                    _.get(data, "pax[1]", ""),
                    _.get(data, "pax[2]", "")
                ],
                discount: _.get(data, "discount", ""),
                notes: {
                    operations: _.get(data, "notes.operations", "") || "",
                    finance: _.get(data, "notes.finance", "") || "",
                    staff: _.get(data, "notes.staff", "") || ""
                },
                activity: _.get(data, 'activity', ""),
                customer: {
                    name: _.get(data, 'customer.name', ""),
                    phone: _.get(data, 'customer.phone', ""),
                },
                channel: {
                    ref: _.get(data, 'channel.ref', "")
                },
                invoice: {
                    total: _.get(data, 'invoice.total', []),
                    status: _.get(data, 'invoice.status', "UNKNOWN")
                }
            }}
            onSubmit={processSubmit}
        >
            {props => (
                <form className="kt-form" onSubmit={props.handleSubmit}>
                    <Row>
                        <Col sm="3">
                            <div className="form-group">
                                <label>Pickup time/location</label>
                                <Field type="text" className="form-control" name="notes.operations" placeholder="08:00 Union" />
                            </div>
                        </Col>
                        <Col sm="3">
                            <div className="form-group">
                                <label>PAX</label>
                                <div className="input-group">
                                    <Field type="number" className="form-control" name="pax[0]" placeholder="0" />
                                    <Field type="number" className="form-control" name="pax[1]" placeholder="0" />
                                    <Field type="number" className="form-control" name="pax[2]" placeholder="0" />
                                </div>
                            </div>
                        </Col>
                        <Col sm="3">
                            <div className="form-group">
                                <label>Activity</label>
                                <Field className="form-control" as="select" name="activity">
                                    {Object.keys(products).map((product, n) => <option value={product} key={n}>{`${products[product].code} / ${products[product].name}`}</option>)}
                                </Field>
                            </div>
                        </Col>
                        <Col sm="3">
                            <div className="form-group">
                                <label>Lead traveller</label>
                                <div className="input-group">
                                    <Field type="text" className="form-control" name="customer.name" />
                                    <Field type="text" className="form-control" name="customer.phone" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3">
                            <div className="form-group">
                                <label>Seller & ref. code</label>
                                <Field type="text" className="form-control" name="channel.ref" />
                            </div>
                        </Col>
                        <Col sm="3">
                            <div className="form-group">
                                <label>Total</label>
                                <div className="input-group">
                                    <Field type="number" className="form-control" name="invoice.total[0]" placeholder="0" />
                                    <Field type="text" className="form-control" name="invoice.total[1]" placeholder="EUR" />
                                </div>
                            </div>
                        </Col>
                        <Col sm="3">
                            <div className="form-group">
                                <label>Payment status</label>
                                <Field type="text" className="form-control" name="invoice.status" />
                            </div>
                        </Col>
                        <Col sm="3">
                            <div className="form-group">
                                <label>Financial note</label>
                                <Field type="text" className="form-control" name="notes.finance" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3">
                            <div className="form-group">
                                <label>Discount</label>
                                <Field type="text" className="form-control" name="discount" />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="form-group">
                                <label>Notes</label>
                                <Field type="text" className="form-control" name="notes.staff" />
                            </div>
                        </Col>
                        <Col sm="3">
                            <div className="form-group">
                                <label>&nbsp;</label>
                                <Row>
                                    <Col sm="6">
                                        <button type="button" onClick={() => setShowEditor(false)} className="btn btn-label-danger btn-block btn-upper">Cancel</button>
                                    </Col>
                                    <Col sm="6">
                                        <button type="submit" className="btn btn-label-success btn-block btn-upper">Save</button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </form>
            )}
        </Formik>
    );
};

const Info = ({ load, activity }) => {
    const [showEditor, setShowEditor] = useState(false);

    let data = activity.data;

    if (activity.status === 'pending') return (
        <tr>
            <td colSpan="11">
                Fetching activity, please wait... <span className="spinner spinner-track spinner-primary spinner-sm ml-3"></span>
            </td>
        </tr>
    );

    return (
        (!showEditor) ? (
            <tr>
                <th scope="row" className="align-middle">{data.notes.operations}</th>
                <td className="align-middle">{data.pax.join(' / ')}</td>
                <td className="align-middle">{data.activity}</td>
                <td className="align-middle">{data.customer.name}, {data.customer.phone}</td>
                <td className="align-middle">{data.channel.ref}</td>
                <td className="align-middle">{data.invoice.total.join(' ')}</td>
                <td className="align-middle">{data.invoice.status}</td>
                <td className="align-middle">{data.notes.finance}</td>
                <td className="align-middle">{data.discount}</td>
                <td className="align-middle">{data.notes.staff}</td>
                <td className="align-middle">
                    <button className="btn btn-sm btn-clean btn-icon mr-2" onClick={() => setShowEditor(!showEditor)}>
                        <i className="flaticon-edit"></i>
                    </button>
                    <button className="btn btn-sm btn-clean btn-icon mr-2" onClick={() => setShowEditor(!showEditor)}>
                        <i className="flaticon-search"></i>
                    </button>
                    <button className="btn btn-sm btn-clean btn-icon" onClick={() => setShowEditor(!showEditor)}>
                        <i className="flaticon-close text-danger"></i>
                    </button>
                </td>
            </tr>
        ) : (
            <tr>
                <td colSpan="11">
                    <Editor load={load} activity={activity} setShowEditor={setShowEditor} />
                </td>
            </tr>
        )
    )
};

export default ({ load, tour }) => {
    const [addActivity, showAddActivity] = useState(false);
    const [selection, setSelection] = useState([]);
    const [submitting, setSubmitting] = useState(false);

    const AttachToTour = async () => {
        setSubmitting(true);
        await API.tours.activities.attach({
            tour: tour._id,
            activities: selection.map(item => item.id)
        });
        setSelection([]);
        setSubmitting(false);
        showAddActivity(false);
        load();
    };

    const range = {
        from: moment(tour.date, 'YYYYMMDD').startOf('day'),
        to: moment(tour.date, 'YYYYMMDD').endOf('day')
    };

    const reload = _.debounce(() => load(), 2000, { 'maxWait': 5000 });

    useEffect(() => {
        if (_.find(tour.activities, ['status', 'pending'])) reload();
    }, [tour]);

    return (
        <Portlet>
            <PortletHeader title="Activities" toolbar={
                <PortletHeaderToolbar>
                    {(selection.length !== 0) && (
                        <button className="btn btn-success btn-sm btn-upper btn-bold mr-3" onClick={AttachToTour} disabled={(submitting) && "disabled"}>
                            Add to tour
                        </button>
                    )}
                    <button onClick={() => showAddActivity(!addActivity)} className="btn btn-success btn-sm btn-upper btn-bold">{addActivity ? "Close" : "Add"}</button>
                </PortletHeaderToolbar>
            } />
            {(addActivity) && (
                <PortletBody>
                    <Bookings selector={e => setSelection(e.selectedRows)} range={{to: range.to.unix(), from: range.from.unix()}} />
                </PortletBody>
            )}
            <PortletBody>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Pickup</th>
                            <th scope="col">PAX</th>
                            <th scope="col">Activity</th>
                            <th scope="col">Lead traveller</th>
                            <th scope="col">Seller & ref. code</th>
                            <th scope="col">Total</th>
                            <th scope="col">Payment status</th>
                            <th scope="col">Financial note</th>
                            <th scope="col">Discount</th>
                            <th scope="col">Notes</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tour.activities.map((activity, n) => (
                            <Info key={n} load={load} activity={activity} />
                        ))}
                    </tbody>
                </table>
            </PortletBody>
        </Portlet>
    )
};