import React, { useEffect, useState, useContext } from "react";
import {
    Formik,
    useFormikContext,
    Field,
} from 'formik';
import _ from "lodash";

import { Row, Col } from 'reactstrap';

import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../layout/elements/Portlet';
import { ListItem as ActivityWidget } from '../../../layout/elements/widgets/Activities';

import API from '../../../services/api';

import { StorageContext } from '../../../StorageProvider';

const Edit = ({ tour, load, vouchers, list, editing }) => {
    const processSubmit = (values, actions) => {
        let payload = {};

        values.vouchers.forEach(item => {
            payload[item.voucher] = item.add;
        });

        API.tours.update({ id: tour._id, payload: { vouchers: payload } }).then(async () => {
            await load();
            editing(false);
        });
    };

    return (
        <Formik
            initialValues={{
                vouchers: Object.keys(list).map(e => (
                    {
                        voucher: e,
                        desc: vouchers[e].desc,
                        num: list[e][0],
                        add: list[e][1] || [0,0,0]
                    }
                ))
            }}
            onSubmit={processSubmit}
        >
            {props => (
                <form className="kt-form" onSubmit={props.handleSubmit}>
                    {props.values.vouchers.map((value, index) => (
                        <Row key={index} className="mb-3">
                            <Col sm="8" className="pt-2">
                                <span className="font-weight-bold pr-3">{value.voucher}</span>{value.desc}<span className="pl-3 text-muted">&times; {value.num}</span>
                            </Col>
                            <Col sm="4">
                                <div className="input-group">
                                    <Field type="number" className="form-control" name={`vouchers[${index}].add[0]`} placeholder="Value" />
                                    <Field type="number" className="form-control" name={`vouchers[${index}].add[1]`} placeholder="Value" />
                                    <Field type="number" className="form-control" name={`vouchers[${index}].add[2]`} placeholder="Value" />
                                </div>
                            </Col>
                        </Row>
                    ))}
                    <button type="submit" className="btn btn-label-success btn-block btn-upper mt-3">Save</button>
                </form>
            )}
        </Formik>
    );
    
    return (
        <React.Fragment>
            {Object.keys(list).map((voucher, n) => (
                <div key={n} className="d-flex align-items-center mb-2" >
                    <div className="symbol symbol-35 mr-3">
                        <span className="symbol-label font-weight-bold">{voucher}</span>
                    </div>
                    <div className="d-flex flex-column font-weight-bold">
                        <span className="text-dark text-hover-primary font-size-lg">{vouchers[voucher].desc}</span>
                        <span className="text-muted">&times; {list[voucher]}</span>
                    </div>
                </div>
            ))}
        </React.Fragment>
    );
};

const View = ({ vouchers, list }) => {
    return (
        <React.Fragment>
            {Object.keys(list).map((voucher, n) => (
                <div key={ n } className="d-flex align-items-center mb-2" >
                    <div className="symbol symbol-35 mr-3">
                        <span className="symbol-label font-weight-bold">{voucher}</span>
                    </div>
                    <div className="d-flex flex-column font-weight-bold">
                        <span className="text-dark">{vouchers[voucher].desc}</span>
                        <span className="text-muted">&times; {list[voucher][0]} {(list[voucher][1] !== 0) && (`+ ` + list[voucher][1])}</span>
                    </div>
                </div>
            ))}
        </React.Fragment>
    );

    /*return (
        <div className="d-flex align-items-center mb-2">
            <div className="symbol symbol-35 mr-3">
                <span className="symbol-label font-weight-bolder">{voucher}</span>
            </div>
            <div className="d-flex flex-column font-weight-bold">
                <span className="text-dark text-hover-primary font-size-lg">{vouchers[voucher].desc}</span>
                <span className="text-muted">&times; {list[voucher]}</span>
            </div>
        </div>
    )

    return (
        <div className="kt-widget kt-widget--user-profile-3">
            <div className="kt-widget__top">
                <div className="kt-widget__content p-0">
                    <div className="kt-widget__head">
                        <span className="kt-widget__username">{voucher} / {vouchers[voucher].desc}</span>
                        <span className="ml-2"><i className="flaticon-network mr-2"></i>{list[voucher]}</span>

                        <div className="kt-widget__action">
                            <button type="button" className="btn btn-primary btn-sm btn-upper" onClick={() => setShowEditor(!showEditor)}>{(showEditor) ? "Close" : "Edit"}</button>
                        </div>
                    </div>
                </div>
            </div>
            {(showEditor) && (
                <div className="item">
                    <Formik
                        initialValues={{
                            pax: list[voucher]
                        }}
                        onSubmit={processSubmit}
                    >
                        {props => (
                            <form className="kt-form kt-form--label-right" onSubmit={props.handleSubmit}>
                                <div className="form-group row mb-0">
                                    <label className="col-2 col-form-label">Pax</label>
                                    <div className="col-7">
                                        <Field type="text" className="form-control" name="pax" placeholder="2" />
                                    </div>
                                    <div className="col-3">
                                        <button type="submit" className="btn btn-label-success btn-block btn-upper">Save</button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            )}
        </div>
    )*/
};

export default ({ load, tour }) => {
    const { vouchers } = useContext(StorageContext).settings;
    const [editing, setEditing] = useState(false);

    return (
        <Portlet>
            <PortletHeader title="Vouchers" toolbar={
                <PortletHeaderToolbar>
                    <button className="btn btn-primary btn-sm btn-upper btn-bold" onClick={() => setEditing(!editing)}>{(editing) ? "Close" : "Edit"}</button>
                </PortletHeaderToolbar>
            } />
            { (editing) ? (
                <PortletBody>
                    <Edit vouchers={vouchers} list={tour.vouchers} tour={tour} load={load} editing={setEditing} />
                </PortletBody>
            ) : (
                <PortletBody>
                    <View vouchers={vouchers} list={tour.vouchers} />
                </PortletBody>
            )}
        </Portlet>
    )
};