import axios from 'axios';

const base = 'https://api.boo.slovenia-explorer.com';

axios.interceptors.request.use(async config => {
    config.url = `${base}${config.url}`;
    config.headers.Authorization = await getToken();
    return config;
});

export default {
    login: (email, type) => {
        return axios.post(`/login`, { email: email, type: type }).then(res => res.data);
    },
    bookings: {
        list: (range) => {
            return axios.get(`/bookings/list`, {
                params: {
                    ...range
                }
            }).then(res => res.data);
        }
    },
    tours: {
        get: ({id}) => {
            return axios.get(`/tours/${id}`).then(res => res.data);
        },
        list: (range) => {
            return axios.get(`/tours`, {
                params: {
                    ...range
                }
            }).then(res => res.data);
        },
        create: ({date, ids}) => {
            return axios.put(`/tours`, {
                date,
                activities: ids
            }).then(res => res.data);
        },
        update: ({id, payload}) => {
            return axios.post(`/tours/${id}`, payload).then(res => res.data);
        },
        activities: {
            get: ({tour}) => {
                return axios.get(`/tours/${tour}/activities`).then(res => res.data);
            },
            update: ({tour, activity, payload}) => {
                return axios.post(`/tours/${tour}/activities/${activity}`, payload).then(res => res.data);
            },
            attach: ({tour, activity, activities}) => {
                if (activity) return axios.put(`/tours/${tour}/activities/${activity}`).then(res => res.data);
                return axios.put(`/tours/${tour}/activities`, {activities: activities}).then(res => res.data);
            }
        }
    
    },
    settings: {
        get: (id) => {
            if (id) return axios.get(`/settings/${id}`).then(res => res.data);
            return axios.get(`/settings`).then(res => res.data);
        }
    },
    document: {
        get: (tour, type) => {
            return $raw({ path: `/tours/${tour}/pdf/${type}`, responseType: 'blob' });
        }
    },
    export: {
        tour: (range) => {
            return $raw({
                path: `/tours/export`,
                responseType: 'blob',
                params: {
                    ...range
                }
            });
        }
    }
};

let getToken = () => {
    return import('./auth').then(r => {
        let token = r.default.status.get()._t;
        return token || null
        //console.log(r);
    }).catch(e => {
        console.error(e);
    });
};

let $raw = async ({ method = 'GET', path, payload = undefined, params = undefined, responseType = undefined }) => {
    return axios({
        method,
        url: path,
        baseURL: base.api,
        data: payload,
        params: params,
        responseType,
        headers: {
            "Authorization": await getToken()
        }
    });
};