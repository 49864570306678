import React, { useEffect, useState, useContext } from "react";
import {
    Formik,
    FormikHelpers,
    FormikProps,
    Form,
    Field,
    FieldArray,
    FieldProps,
} from 'formik';
import _ from "lodash";

import MaskedInput from 'react-text-mask';
import { Row, Col } from 'reactstrap';

import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../layout/elements/Portlet';
import { Widget1 as Widget } from '../../../layout/elements/Widgets';

import API from '../../../services/api';

import { StorageContext } from '../../../StorageProvider';

const FormMasks = {
    hours: [[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/], "00:00"]
};

const OtherExpenses = ({ load, tour, editingControl }) => {
    const [editing, setEditing] = editingControl;
    let data = _.get(tour.data, 'expenses.other', [[]]);

    const processSubmit = (values, actions) => {
        let payload = values.expenses;

        API.tours.update({ id: tour._id, payload: { expenses: { other: payload } } }).then(async () => {
            await load();
            setEditing(false);
        });
    };

    const editor = (
        <Formik
            initialValues={{
                expenses: data
            }}
            onSubmit={processSubmit}
        >
            {props => (
                <form className="kt-form" onSubmit={props.handleSubmit}>{console.log(props.values)}
                    <FieldArray
                        name="expenses"
                        render={arrayHelpers => (
                            <React.Fragment>
                                {props.values.expenses.map((value, index) => (
                                    <div className="form-group">
                                        <Row key={index}>
                                            <Col sm="3">
                                                <Field type="text" className="form-control" name={`expenses[${index}].service`} placeholder="Service" />
                                            </Col>
                                            <Col sm="3">
                                                <Field type="text" className="form-control" name={`expenses[${index}].desc`} placeholder="Description" />
                                            </Col>
                                            <Col sm="4">
                                                <div className="input-group">
                                                    <Field type="number" className="form-control" name={`expenses[${index}].value`} placeholder="Value" />
                                                    <Field type="text" className="form-control" name={`expenses[${index}].currency`} placeholder="Currency" />
                                                </div>
                                            </Col>
                                            <Col sm="2">
                                                <button type="button" onClick={() => arrayHelpers.remove(index)} className="btn btn-label-danger btn-block btn-upper">Remove</button>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <div className="form-group mb-0">
                                    <Row>
                                        <Col sm="6">
                                            <button type="button" onClick={() => arrayHelpers.push({})} className="btn btn-label-info btn-block btn-upper">Add row</button>
                                        </Col>
                                        <Col sm="6">
                                            <button type="submit" className="btn btn-label-success btn-block btn-upper">Save</button>
                                        </Col>
                                    </Row>
                                </div>
                            </React.Fragment>
                        )}
                    />
                </form>
            )}
        </Formik>
    );

    const viewer = (
        <Widget>
            <Row className="items">
                <Col sm="4">
                    <h5>Service</h5>
                </Col>
                <Col sm="4">
                    <h5>Description</h5>
                </Col>
                <Col sm="4">
                    <h5>Value</h5>
                </Col>
            </Row>
            {data.map((value, index) => (
                <Row key={index} className="items">
                    <Col sm="4">
                        <p className="kt-font-info">{value.service || "-"}</p>
                    </Col>
                    <Col sm="4">
                        <p className="kt-font-info">{value.desc || "-"}</p>
                    </Col>
                    <Col sm="4">
                        <p className="kt-font-info">{value.value || "-"} {value.currency || "-"}</p>
                    </Col>
                </Row>
            ))}
        </Widget>
    );

    return (editing) ? editor : viewer;
};

const TravelExpenses = ({ load, tour, editingControl }) => {
    const [editing, setEditing] = editingControl;
    let data = _.get(tour.data, 'expenses.travel', {});

    const processSubmit = (values, actions) => {
        let payload = {
            km: {
                start: values.km.start,
                end: values.km.end
            },
            time: {
                start: values.time.start,
                end: values.time.end
            },
            gas: values.gas,
            carwash: values.carwash,
            lunch: values.lunch,
            other: values.other
        };

        API.tours.update({ id: tour._id, payload: { expenses: { travel: payload }}}).then(async () => {
            await load();
            setEditing(false);
        });
    };

    const editor = (
        <Formik
            initialValues={{
                km: {
                    start: _.get(data, 'km.start', ""),
                    end: _.get(data, 'km.end', "")
                },
                time: {
                    start: _.get(data, 'time.start', ""),
                    end: _.get(data, 'time.end', "")
                },
                gas: _.get(data, 'gas', ["", ""]),
                carwash: _.get(data, 'carwash', ["", ""]),
                lunch: _.get(data, 'lunch', [""]),
                other: _.get(data, 'other', ["", ""])
            }}
            onSubmit={processSubmit}
        >
            {props => (
                <form className="kt-form" onSubmit={props.handleSubmit}>
                    <div className="form-group">
                        <Row>
                            <Col sm="6">
                                <label>Start</label>
                                <div className="input-group">
                                    <Field type="number" className="form-control" name="km.start" placeholder="0" />
                                    <div className="input-group-append"><span className="input-group-text"> km</span></div>
                                </div>
                            </Col>
                            <Col sm="6">
                                <label>End</label>
                                <div className="input-group">
                                    <Field type="number" className="form-control" name="km.end" placeholder="0" />
                                    <div className="input-group-append"><span className="input-group-text"> km</span></div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group">
                        <Row>
                            <Col sm="6">
                                <label>Start</label>
                                <div className="input-group">
                                    {/*<Field type="text" className="form-control" name="time.start" placeholder="0" />*/}
                                    <Field name="time.start" render={({ field }) => {
                                        return <MaskedInput type="text" className="form-control" mask={FormMasks.hours[0]}
                                            {...field}
                                            placeholder={FormMasks.hours[1]} />
                                    }} />
                                    <div className="input-group-append"><span className="input-group-text"> h</span></div>
                                </div>
                            </Col>
                            <Col sm="6">
                                <label>End</label>
                                <div className="input-group">
                                    {/*<Field type="text" className="form-control" name="time.end" placeholder="0" />*/}
                                    <Field name="time.end" render={({ field }) => {
                                        return <MaskedInput type="text" className="form-control" mask={FormMasks.hours[0]}
                                            {...field}
                                            placeholder={FormMasks.hours[1]} />
                                    }} />
                                    <div className="input-group-append"><span className="input-group-text"> h</span></div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group">
                        <Row>
                            <Col sm="6">
                                <label>Gas</label>
                                <div className="input-group">
                                    <Field type="text" className="form-control" name="gas[0]" placeholder="0" />
                                </div>
                            </Col>
                            <Col sm="6">
                                <label>&nbsp;</label>
                                <div className="input-group">
                                    <Field type="number" className="form-control" name="gas[1]" placeholder="0" />
                                    <div className="input-group-append"><span className="input-group-text"> l</span></div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group">
                        <Row>
                            <Col sm="6">
                                <label>Carwash</label>
                                <div className="input-group">
                                    <Field type="number" className="form-control" name="carwash[0]" placeholder="0" />
                                    <div className="input-group-append"><span className="input-group-text"> &euro;</span></div>
                                </div>
                            </Col>
                            <Col sm="6">
                                <label>&nbsp;</label>
                                <div className="input-group">
                                    <Field type="number" className="form-control" name="carwash[1]" placeholder="0" />
                                    <div className="input-group-append"><span className="input-group-text"> #</span></div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group">
                        <Row>
                            <Col sm="6">
                                <label>Lunch</label>
                                <div className="input-group">
                                    <Field type="number" className="form-control" name="lunch[0]" placeholder="0" />
                                    <div className="input-group-append"><span className="input-group-text"> &euro;</span></div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group">
                        <Row>
                            <Col sm="6">
                                <label>Other</label>
                                <div className="input-group">
                                    <Field type="number" className="form-control" name="other[0]" placeholder="0" />
                                    <div className="input-group-append"><span className="input-group-text"> &euro;</span></div>
                                </div>
                            </Col>
                            <Col sm="6">
                                <label>&nbsp;</label>
                                <div className="input-group">
                                    <Field type="text" className="form-control" name="other[1]" placeholder="0" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <button type="submit" className="btn btn-label-success btn-block btn-upper">Save</button>
                </form>
            )}
        </Formik>
    );

    const viewer = (
        <Widget>
            <Row className="items">
                <Col sm="6">
                    <h5>Start km</h5>
                    <p className="kt-font-info">{data.km && data.km.start || "-"}</p>
                </Col>
                <Col sm="6">
                    <h5>End km</h5>
                    <p className="kt-font-info">{data.km && data.km.end || "-"}</p>
                </Col>
            </Row>
            <Row className="items">
                <Col sm="6">
                    <h5>Start time</h5>
                    <p className="kt-font-info">{data.time && data.time.start || "-"}</p>
                </Col>
                <Col sm="6">
                    <h5>End time</h5>
                    <p className="kt-font-info">{data.time && data.time.end || "-"}</p>
                </Col>
            </Row>
            <Row className="items">
                <Col sm="2">
                    <h5>Gas</h5>
                </Col>
                <Col sm="5">
                    <p className="kt-font-info">{data.gas && data.gas[0] || "-"}</p>
                </Col>
                <Col sm="5">
                    <p className="kt-font-info">{data.gas && data.gas[1] || "-"} l</p>
                </Col>
            </Row>
            <Row className="items">
                <Col sm="2">
                    <h5>Wash</h5>
                </Col>
                <Col sm="5">
                    <p className="kt-font-info">{data.carwash && data.carwash[0] || "-"} &euro;</p>
                </Col>
                <Col sm="5">
                    <p className="kt-font-info">{data.carwash && data.carwash[1] || "-"} #</p>
                </Col>
            </Row>
            <Row className="items">
                <Col sm="2">
                    <h5>Lunch</h5>
                </Col>
                <Col sm="5">
                    <p className="kt-font-info">{data.lunch && data.lunch[0] || "-"} &euro;</p>
                </Col>
            </Row>
            <Row className="items">
                <Col sm="2">
                    <h5>Other</h5>
                </Col>
                <Col sm="5">
                    <p className="kt-font-info">{data.other && data.other[0] || "-"} &euro;</p>
                </Col>
                <Col sm="5">
                    <p className="kt-font-info">{data.other && data.other[1] || "-"}</p>
                </Col>
            </Row>
        </Widget>
    );

    return (editing) ? editor : viewer;
};

export default ({ load, tour, ...types }) => {
    const [editing, setEditing] = useState(false);
    const [submit, setSubmit] = useState(null);

    let data = _.get(tour.data, 'transports[0]', null);

    const processSubmit = (values, actions) => {
        let payload = {
            code: values.code,
            km: {
                start: values.startKm,
                end: values.endKm
            },
            expenses: {
                gas: values.exGas,
                carwash: values.exCarwash
            },
            notes: (values.notes === "") ? null : values.notes
        };

        API.tours.update({ id: tour._id, payload: { transports: [payload] } }).then(async () => {
            await load();
            setEditing(false);
        });
    };

    const editor = (
        <Formik
            initialValues={{
                startKm: _.get(data, 'km.start', 0),
                endKm: _.get(data, 'km.end', 0),
                exGas: _.get(data, 'expenses.gas', 0),
                exCarwash: _.get(data, 'expenses.carwash', 0),
                notes: _.get(data, 'notes', "")
            }}
            onSubmit={processSubmit}
        >
            {props => (
                <form className="kt-form" onSubmit={props.handleSubmit}>
                    <div className="form-group">
                        <Row>
                            <Col sm="6">
                                <label>Start</label>
                                <div className="input-group">
                                    <Field type="text" className="form-control" name="startKm" placeholder="0" />
                                    <div className="input-group-append"><span className="input-group-text">km</span></div>
                                </div>
                            </Col>
                            <Col sm="6">
                                <label>End</label>
                                <div className="input-group">
                                    <Field type="text" className="form-control" name="endKm" placeholder="0" />
                                    <div className="input-group-append"><span className="input-group-text">km</span></div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group">
                        <Row>
                            <Col sm="6">
                                <label>Gas</label>
                                <div className="input-group">
                                    <Field type="text" className="form-control" name="exGas" placeholder="0" />
                                    <div className="input-group-append"><span className="input-group-text">&euro;</span></div>
                                </div>
                            </Col>
                            <Col sm="6">
                                <label>Carwash</label>
                                <div className="input-group">
                                    <Field type="text" className="form-control" name="exCarwash" placeholder="0" />
                                    <div className="input-group-append"><span className="input-group-text">&euro;</span></div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group">
                        <label>Notes</label>
                        <Field as="textarea" className="form-control" rows="3" name="notes" placeholder="Notes" />
                    </div>
                    <button type="submit" className="btn btn-label-success btn-block btn-upper">Save</button>
                </form>
            )}
        </Formik>
    );

    if (types.travel) {
        return (
            <Portlet>
                <PortletHeader title="Travel expenses" toolbar={
                    <PortletHeaderToolbar>
                        <button className="btn btn-primary btn-sm btn-upper btn-bold" onClick={() => setEditing(!editing)}>{editing ? "Close" : "Edit"}</button>
                    </PortletHeaderToolbar>
                } />
                <PortletBody>
                    <TravelExpenses tour={tour} load={load} editingControl={[editing, setEditing]} />
                </PortletBody>
            </Portlet>
        )
    } else {
        return (
            <Portlet>
                <PortletHeader title="Other expenses" toolbar={
                    <PortletHeaderToolbar>
                        <button className="btn btn-primary btn-sm btn-upper btn-bold" onClick={() => setEditing(!editing)}>{editing ? "Close" : "Edit"}</button>
                    </PortletHeaderToolbar>
                } />
                <PortletBody>
                    <OtherExpenses tour={tour} load={load} editingControl={[editing, setEditing]} />
                </PortletBody>
            </Portlet>
        )
    }
};