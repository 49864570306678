import firebase from 'firebase/app';
import 'firebase/auth';

import API from './api';
import Storage from './storage';

let debug = true;

var config = {
    apiKey: "AIzaSyBrmT7tUp1uxKobrqkjYgIymlIVoMjhb90",
    authDomain: "slovenia-explorer-e5549.firebaseapp.com",
    databaseURL: "https://slovenia-explorer-e5549.firebaseio.com",
    projectId: "slovenia-explorer-e5549",
    storageBucket: "slovenia-explorer-e5549.appspot.com",
    messagingSenderId: "118949485163",
    appId: "1:118949485163:web:6b0b9ed9e630275040ef2e"
};
firebase.initializeApp(config);

let Auth;

export default Auth = {
    status: {
        _v: {
            _a: false,
            _t: null,
            _u: null
        },
        get: () => Auth.status._v,
        set: (val) => Auth.status._v = val
    },
    state: (callback) => {
        firebase.auth().onAuthStateChanged((user) => {
            log(['auth.state', user]);
            if (user) {
                Auth.status.set({ _a: true, _t: user._lat, _u: user.uid });
                callback(user);
                Auth._interval = setInterval(Auth.refreshToken, 10 * 60 * 1000);
            } else {
                Auth.status.set({ _a: false, _t: null, _u: null });
                callback(user);
                if (Auth._interval) clearInterval(Auth._interval);
            }
        });
    },
    refreshToken: () => {
        firebase.auth().currentUser.getIdToken(true).then(token => {
            log(['auth.refreshtoken']);
            let curr = Auth.status.get();
            Auth.status.set({ _a: curr._a, _t: token, _u: curr._u });
        });
    },
    login: {
        link: {
            send: (email) => {
                return API.login(email).then((res) => {
                    Storage.set('signin:email', email);
                    return res;
                }).catch(err => {
                    console.error(err);
                    throw new Error(err);
                });
            },
            complete: () => {
                log(['login.link.complete']);
                let email = Storage.get('signin:email');
                if (!email) {
                    email = window.prompt('Please provide your email for confirmation');
                }
                return firebase.auth().signInWithEmailLink(email, window.location.href).then((res) => {
                    Storage.remove('signin:email');
                    return res;
                });
                /*if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
                    let email = Storage.get('signin:email');
                    if (!email) {
                        email = window.prompt('Please provide your email for confirmation');
                    }
                    return firebase.auth().signInWithEmailLink(email, window.location.href).then((result) => {
                        Storage.remove('signin:email');
                        return result.user.uid;
                    }).catch((err) => {
                        console.error(err);
                    });
                }*/
            }
        }
    },
    logout: () => {
        return firebase.auth().signOut();
    }
};

function log(message) {
    if (debug) console.log("[Firebase]", message);
};