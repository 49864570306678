import React, { useState } from "react";

import Header from './segments/Header';
import SubHeader from './segments/SubHeader';
import Body from './segments/Body';
import Footer from './segments/Footer';

const Wrapper = ({ type, children }) => {
    switch (type) {
        case "login":
            return (
                <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
                    <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin">
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                            {children}
                        </div>
                    </div>
                </div>
            )
        default:
            return (
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                            {children}
                        </div>
                    </div>
                </div>
            )
    }
};

export const LayoutContext = React.createContext();

export const LayoutProvider = ({children}) => {
    const [subHeader, setSubHeader] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState(null);
    const [history, setHistory] = useState(null);

    return (
        <LayoutContext.Provider value={{
            __history: [history, setHistory],
            elements: {
                subheader: [subHeader, setSubHeader],
                breadcrumbs: [breadcrumbs, setBreadcrumbs]
            }
        }}>
            {children}
        </LayoutContext.Provider>
    )
};

export default ({ header, footer, subheader, type, children }) => {
    const Main = () => {
        switch (type) {
            case "login":
                return (
                    <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                        <div className="kt-login__container">
                            {children}
                        </div>
                    </div>
                )
            default:
                return (
                    <div className="kt-body kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-grid--stretch" >
                        <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                            {subheader && <SubHeader />}
                            <Body type={type}>
                                {children}
                            </Body>
                        </div>
                    </div>
                )
        }
    };

    return (
        <Wrapper type={type}>
            {header && <Header />}
            <Main />
            {footer && <Footer />}
        </Wrapper>
    )
};