import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { LayoutContext } from '../../layout';

const Breadcrumbs = () => {
    let [breadcrumbs] = useContext(LayoutContext).elements.breadcrumbs;

    return (
        <React.Fragment>
            <h3 className="kt-subheader__title">{breadcrumbs && breadcrumbs.title}</h3>
            <span className="kt-subheader__separator kt-hidden"></span>
            <div className="kt-subheader__breadcrumbs">
                { breadcrumbs && breadcrumbs.links.map(link => (
                    <React.Fragment key={link[1]}>
                        <span className="kt-subheader__breadcrumbs-separator"></span>
                        <Link to={link[1]} className="kt-subheader__breadcrumbs-link">{link[0]}</Link>
                    </React.Fragment>
                ))}
            </div>
        </React.Fragment>
    )
};

export const SetSubHeader = ({ children }) => {
    let [subHeader, setSubHeader] = useContext(LayoutContext).elements.subheader;

    useEffect(() => {
        setSubHeader(children);
    }, []);

    return null;
};

export default () => {
    let [subHeader] = useContext(LayoutContext).elements.subheader;
    return (
        <div className="kt-subheader kt-grid__item">
            <div className="kt-container">
                <div className="kt-subheader__main">
                    <Breadcrumbs />
                </div>
                <div className="kt-subheader__toolbar">
                    <div className="kt-subheader__wrapper">
                        {subHeader}
                    </div>
                </div>
            </div>
        </div>
    )
};