import React from "react";

export default () => {
    return (
        <div className="kt-footer kt-grid__item" id="kt_footer">
            <div className="kt-container ">
                <div className="kt-footer__wrapper">
                    <div className="kt-footer__copyright">
                        2020&nbsp;&copy;&nbsp;<a href="http://keenthemes.com/metronic" target="_blank" className="kt-link">Keenthemes</a>
                    </div>
                    <div className="kt-footer__menu">
                        <a href="http://keenthemes.com/metronic" target="_blank" className="kt-link">About</a>
                        <a href="http://keenthemes.com/metronic" target="_blank" className="kt-link">Team</a>
                        <a href="http://keenthemes.com/metronic" target="_blank" className="kt-link">Contact</a>
                    </div>
                </div>
            </div>
        </div>
    )
};