import React, { useContext, useEffect } from "react";
import { Redirect, BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { AuthContext } from './AuthProvider';
import Layout, { LayoutContext, LayoutProvider } from './layout';

import * as Pages from './pages';

const Cleaner = ({history}) => {
    const setBreadcrumbs = useContext(LayoutContext).elements.breadcrumbs[1];
    const setSubHeader = useContext(LayoutContext).elements.subheader[1];

    useEffect(() => {
        setBreadcrumbs(null);
        setSubHeader(null);
    }, [history.location.key]);

    return null;
};

const History = ({history}) => {
    const setHistory = useContext(LayoutContext).__history[1];

    useEffect(() => {
        setHistory(history);
    }, []);

    return null;
};

const Wrapper = () => {
    const { auth } = useContext(AuthContext);

    return (
        <LayoutProvider>
            <Route component={History} />
            {(auth) ? (
                <Layout header footer subheader>
                    <Route component={Cleaner} />
                    <Switch>
                        <Route path="/bookings" exact component={Pages.Bookings} />
                        <Route path="/tours" component={Pages.Tours} />
                        <h3>Dashboard</h3>
                    </Switch>
                </Layout>
            ) : (
                    <Layout type="login">
                        <Switch>
                            <Route path="/login/auth/" exact component={Pages.LoginCallback} />
                            <Route path="/login" exact component={Pages.Login} />
                            <Redirect to="/login" />
                        </Switch>
                    </Layout>
                )}
            <Route path="/logout" exact component={Pages.Logout} />
        </LayoutProvider>
    )
};

export default () => (
    <Router>
        <Wrapper />
    </Router>
);