import _ from 'lodash';
import moment from 'moment';

let debug = true;

const Storage = {
    _s: {},
    available: false,
    get: (key) => {
        log(['get', key]);
        if (Storage.available) {
            return window.localStorage.getItem(key) || undefined;
        } else {
            return Storage._s[key] || undefined;
        }
    },
    set: (key, value = moment().unix()) => {
        log(['set', key, value]);
        if (Storage.available) {
            return window.localStorage.setItem(key, value);
        } else {
            return Storage._s[key] = value;
        }
    },
    remove: (key) => {
        if (Storage.available) {
            return window.localStorage.removeItem(key);
        } else {
            return delete Storage._s[key];
        }
    }
}

function storageAvailable(type) {
    try {
        var storage = window[type],
            x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0;
    }
}

if (storageAvailable('localStorage')) {
    log('available');
    Storage.available = true;
} else {
    log('not available');
    Storage.available = false;
}

function log(message) {
    if (debug) console.log("[Storage]", message);
}

export default Storage;